<template>
  <div class="companynews">
    <div class="line" />
    <h2>公司新闻</h2>
    <div class="news-wrapper">
      <div
        v-for="(item, index) in newLists"
        :key="index"
        class="news-item"
        @click="JumpNews(item.newsId, item)"
      >
        <div class="news-img">
          <img :src="item.img" width="259" height="200" alt="">
        </div>
        <div class="news-content">
          <div class="news-date">
            <span>{{ item.date.substring(8,10) }}</span>
            <span />
            <span>{{ item.date.substring(5,7) }}</span>
            <span>{{ item.date.substring(0,4) }}</span>
          </div>
          <div class="news-line" />
          <div class="news-right">
            <h2>{{ item.title }}</h2>
            <p v-html="item.infor" />
          </div>
        </div>
      </div>
    </div>

    <div class="next-button">
      <!-- <span class="icon-wrap-left">
        <i class="el-icon-arrow-left" />
      </span>
      <span class="icon-wrap-right">
        <i class="el-icon-arrow-right" />
      </span> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newLists: [
        // { date: '2022-01-06', img: require('../../assets/img/news0.jpg'), title: '山水物联召开沈本单元运输单位线上培训交流会', infor: '山水物联在沈阳国际软件园召开2022年线上培训交流会，会议由山水物联总经理王广魁主持，总经理助理刘知非，总经理助理李峰，18个运输单位和2个子公司采购负责人参加了本次会议。', content: ``, newsId: 0 },
        // { date: '2021-12-26', img: require('../../assets/img/news1.jpg'), title: '山水物联参加吉林白山绿色低碳新材料产业园概念性规划评审会暨区情推介会', infor: '山水集团副总裁兼东北运营区总经理赵宏波，东北运营区书记、副总经理李益华，东北运营区副总经理孙立勇，白山山水公司总经理丁坤国，山水物联总经理王广魁，总经理助理李峰参加吉林白山绿色低碳新材料产业园概念性规划评审会暨区情推介会。', content: ``, newsId: 1 },
        // { date: '2021-12-09', img: require('../../assets/img/news2.jpg'), title: '东北运营区领导莅临山水物联指导工作', infor: '山水集团副总裁兼东北运营区总经理赵宏波、副总经理吴振疆和副总经理孙立勇一行三人莅临山水物联指导工作。', content: ``, newsId: 2 },
        // { date: '2021-10-13', img: require('../../assets/img/news3.jpg'), title: '东北运营区召开九月份经济活动分析会', infor: '东北运营区在沈阳国际软件园会议室召开九月份经济活动分析会。东北运营区班子、各公司总经理和采购部长、运营区各部室负责人和高级主管参加了会议，各公司班子成员、中层管理人员及销售、生产、采购、管理骨干等以视频形式参加会议。', content: ``, newsId: 3 },
        // { date: '2021-09-25', img: require('../../assets/img/news4.jpg'), title: '山水集团总裁李会宝莅临山水物联检查指导工作', infor: '山水集团总裁李会宝、山水集团副总裁兼东北运营区总经理赵宏波、东北运营区书记李益华等一行六人莅临山水物联检查指导工作。', content: ``, newsId: 4 }
      ]
    }
  },
  methods: {
    JumpNews(id, list) {
      this.$router.push({
        path: '/baseHome/newscontent',
        query: { newsId: id, list: list }
      })
    }
  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";
@import "./../../assets/scss/pages.scss";
.companynews {
  .news-wrapper {
    .news-item {
      &:hover {
        transition: all 0.5s;
        background-color: #0062db;
        .news-date,
        .news-right p,
        .news-right h2 {
          color: #fff !important;
        }
      }
      display: flex;
      width: 100%;
      height: 230px;
      border-radius: 8px;
      margin-bottom: 38px;
      padding: 14px;
      box-sizing: border-box;
      background-color: #fff;
      cursor: pointer;
      .news-content {
        display: flex;
        align-items: center;
        .news-date {
          position: relative;
          color: #888888;
          span:nth-child(1) {
            font-size: 30px;
          }
          span:nth-child(2) {
            display: inline-block;
            width: 1px;
            height: 18px;
            background-color: #888888;
            transform: rotate(30deg);
            margin: 0 6px;
          }
          span:nth-child(3) {
            font-size: 20px;
          }
          span:nth-child(4) {
            position: absolute;
            bottom: -18px;
            left: 20px;
            font-size: 14px;
          }
        }
        .news-line {
          height: 110px;
          width: 1px;
          background-color: #e5e5e5;
          margin: 0 40px;
        }
        .news-right {
          width: 870px;
          h2 {
            margin-bottom: 30px;
          }
          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 30px;
            font-size: 14px;
            color: #888;
          }
        }
      }
    }
    .news-img {
      margin-right: 48px;
    }
  }

  .next-button {
    width: 100%;
    margin-bottom: 158px;
    text-align: center;
    .icon-wrap-left {
      display: inline-block;
      margin-right: 30px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid #0a68dc;
      box-sizing: border-box;
      text-align: center;
      line-height: 56px;
      .el-icon-arrow-left {
        cursor: pointer;
        font-size: 30px;
        color: #0a68dc;
        font-weight: bolder;
      }
    }
    .icon-wrap-right {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid #0a68dc;
      box-sizing: border-box;
      text-align: center;
      line-height: 56px;
      .el-icon-arrow-right {
        cursor: pointer;
        font-size: 30px;
        color: #0a68dc;
        font-weight: bolder;
      }
    }
  }
}
</style>
